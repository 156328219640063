@charset "utf-8";

/* =================================
  header
================================= */
.ttl--site {
  display: inline-block;
  a {
    display: block;
    padding: 11px 0;
    img {
      width: auto;
      height: 25px;
      display: inline-block;
      vertical-align: middle;
    }
    & > span {
      font-size: 1.4rem;
      font-weight: normal;
      color: #000;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 20px;
    }
  }
}
.header {
  position: relative;
  &__top {
    width: 100%;
    padding: 19px 0;
    &__in {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: $base-width;
      margin: 0 auto;
    }
    &__parts {
      display: flex;
      .link {
        font-size: 1.4rem;
        font-weight: bold;
        color: $accent-color-dark;
        border: 2px solid $accent-color;
        border-radius: 2px;
        padding: 10px 18px;
        &:hover {
          color: #fff;
          background: $accent-color;
        }
      }
    }
  }
}
.gNav {
  background: $accent-color-dark;
  .gNav__list {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &__item {
      width: 20%;
      a {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        display: block;
        position: relative;
        text-align: center;
        padding: 31px 0;
        span {
          position: relative;
          &::before {
            font-family: "FontAwesome";
            font-weight: 400;
            font-size: 1.8rem;
            margin-right: 12px;
          }
          &::after {
            content: "";
            width: 0;
            height: 1px;
            display: block;
            background: #fff;
            position: absolute;
            bottom: -7px;
            left: 0;
            @include animation-base(all);
          }
        }
        &:hover {
          opacity: 1;
          span::after {
            width: 100%;
          }
        }
      }
      &:nth-child(1) span::before {
        content: "\f040";
      }
      &:nth-child(2) span::before {
        content: "\f080";
      }
      &:nth-child(3) span::before {
        content: "\f097";
      }
      &:nth-child(4) span::before {
        content: "\f0f7";
      }
      &:nth-child(5) span::before {
        content: "\f091";
      }
    }
  }
  &Form {
    background: #f4f4f4;
    border: 2px solid #d4d4d4;
    border-radius: 2px;
    margin-left: 10px;
    dl {
      display: flex;
    }
    input {
      font-size: 1.6rem;
      padding: 8px 0 8px 10px;
      &::placeholder {
        font-size: 1.4rem;
        color: #656565;
      }
    }
    button {
      cursor: pointer;
      font-size: 1.7rem;
      color: #a3a3a3;
      padding: 12px;
    }
  }
}
@media screen and (max-width: $display-width-s){
  /* =================================
    header
  ================================= */
  .ttl--site {
    width: calc(100% - 135px);
    a {
      padding: 4px 13px 3px 3px;
      img {
        height: 16px;
      }
      & > span {
        display: none;
      }
    }
  }
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    &__top {
      padding: 0;
      background: #fff;
      &__parts {
        position: relative;
        right: 45px;
        .link {
          font-size: 1rem;
          color: #333;
          border: none;
          border-radius: 0;
          text-align: center;
          padding: 9px 7px 1px;
          border-left: 1px solid #b5b5b5;
          &:before {
            content: "\f02d";
            font-family: "FontAwesome";
            font-size: 1.4rem;
            font-weight: normal;
            color: #1f4a96;
            display: block;
          }
        }
      }
    }
    &Nav__cover {
      height: 0;
      width: 100vw;
      background: rgba(8,8,8, 0.6);
      position: absolute;
      top: 45px;
      display: none;
      z-index: -1;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
    }
  }
  .gNav {
    display: none;
    max-height: 100vh;
    overflow-y: auto;
    position: relative;
    top: 0;
    margin: 0;
    padding: 35px 25px 28px;
    background: url(/images/bg_gNab.jpg) no-repeat center top;
    background-size: cover;
    &Form {
      background: transparent;
      border: none;
      border-radius: 0;
      margin-left: 0;
      .searchBtn {
        font-size: 1rem;
        font-weight: bold;
        color: #333;
        text-align: center;
        border-left: 1px solid #b5b5b5;
        padding: 9px 12px 1px;
        &::before {
          content: "\f002";
          font-family: "FontAwesome";
          font-size: 1.4rem;
          font-weight: normal;
          color: #1f4a96;
          display: block;
        }
      }
      form {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9999;
        transform: scale(.9);
        opacity: 0;
        visibility: hidden;
        @include animation-base(all,.2s,linear);
        &.active {
          transform: scale(1);
          opacity: 1;
          visibility: visible;
          .gNavForm__cover {
            position: fixed;
            top: 40px;
            left: 0;
            width: 100%;
            height: 100vh;
            background: rgba(13, 55, 129,.9);
            z-index: 9999;
          }
        }
      }
      dl {
        background: #fff;
        justify-content: center;
        align-items: center;
        padding: 4px 0;
      }
      dt {
        position: relative;
      }
      input {
        font-size: 1.6rem;
        padding: 2px 5px;
        border: 1px solid #d4d4d4;
        &::placeholder {
          font-size: 1.2rem;
        }
      }
      button {
        font-size: 1.4rem;
        padding: 7px 7px 7px 16px;
      }
      .search--closeBtn {
        font-size: 1.4rem;
        color: #a3a3a3;
        position: absolute;
        top: 14px;
        left: 15px;
      }
    }
    .gNav__list {
      display: block;
      &__item {
        width: 100%;
        margin-bottom: 11px;
        a {
          font-size: 1.4rem;
          padding: 20px 0;
          background: $accent-color-dark;
          span {
            position: relative;
            &::before {
              font-size: 1.4rem;
              margin-right: 6px;
            }
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
  /*バーガーボタン設定*/
  .burger {
    width: 45px;
    height: 40px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    cursor: pointer;
    z-index: 205;
    border-left: 1px solid #b5b5b5;
    &::after {
      content: "メニュー";
      font-size: 1rem;
      font-weight: bold;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.is-open::after {
      content: "閉じる";
    }
  }
  .burger span {
    width: 12px;
    height: 2px;
    display: block;
    background: #1e4996;
    position: absolute;
    top: 14px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: width 0.2s, right 0.2s, left 0.2s;
  }
  .burger .burger--top {
    transform: translateY(-4px);
  }
  .burger .burger--middle {
    transform: translateY(0px);
    position: relative;
    background: none;
  }
  .burger .burger--middle:before {
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 2px;
    background: #1e4996;
    transition: all 0.2s;
    transform: rotate(0deg);
    border-radius: 50px;
  }
  .burger .burger--middle:after {
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 2px;
    background: #1e4996;
    transition: all 0.2s;
    transform: rotate(0deg);
    border-radius: 50px;
  }
  .burger .burger--bottom {
    transform: translateY(4px);
  }
  /*クリック後、バツボタンになる*/
  .burger.is-open .burger--top {
    left: 100%;
    width: 0px;
  }
  .burger.is-open .burger--middle:before {
    transform: rotate(135deg);
  }
  .burger.is-open .burger--middle:after {
    transform: rotate(45deg);
  }
  .burger.is-open .burger--bottom {
    right: 100%;
    width: 0px;
  }
  .burgerBtn--close {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1;
    color: #333;
    background: #fff;
    text-align: center;
    width: 46%;
    margin: 15px auto 0;
    padding: 13px 0 17px;
    position: relative;
    &::before {
      content: "\f00d";
      font-family: "FontAwesome";
      font-size: 1.7rem;
      color: #1e4996;
      margin-right: 7px;
    }
  }
}
@charset "utf-8";
/* =================================
  side nav
================================= */
.sideNav {
  /*---------------------------
    サイドナビ共通
  ---------------------------*/
  margin: 52px 0 0;
  &__ttl {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    background: $accent-color;
    padding: 8px 14px 8px 26px;
    display: inline-block;
    position: relative;
    top: -12px;
    &::before {
      content: "";
      width: 27px;
      height: 31px;
      background: url(/images/ttl_sideNavL.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: -12px;
      left: -13px;
    }
    &::after {
      content: "";
      width: 12px;
      height: 32px;
      background: url(/images/ttl_sideNavR.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 0;
      right: -12px;
    }
  }
  &__sec {
    background: #faf9f6;
    & + .sideNav__sec {
      margin: 54px 0 0;
    }
  }
  /*---------------------------
    検索
  ---------------------------*/
  .sideNav__search {
    .sideNav__ttl span {
      position: relative;
      &::after {
        content: "";
        width: 14px;
        height: 14px;
        background: url(/images/icon/icon_search02.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-left: 6px;
      }
    }
  }
  .searchPrefectures {
    &__item {
      padding: 40px 15px 22px;
    }
    &__block {
      & > li + li {
        margin: 20px 0 0;
      }
      &__list {
        li {
          width: calc(25% - 3.75px);
        }
        li:nth-child(4n + 1) {
          margin-left: 0px;
        }
      }
    }
  }
  /*---------------------------
    よく読まれている記事
  ---------------------------*/
  .popular__list {
    counter-reset: popularItem;
    padding: 0 15px 22px;
    li {
      position: relative;
      a {
        font-size: 1.4rem;
        color: #201a0a;
        display: block;
        padding: 15px 6px 15px 50px;
        &:hover {
          background: #d8efa6;
          color: $accent-color;
        }
      }
      &::before {
        content: '0'counter(popularItem);
        counter-increment: popularItem;
        font-size: 1.5rem;
        font-weight: bold;
        color: #392301;
        line-height: 1;
        background: #fff;
        border: 1px solid #625c52;
        padding: 8px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      & + li {
        border-top: 1px dashed #201a0a;
      }
    }
  }
  /*---------------------------
    カテゴリー
  ---------------------------*/
  .sideNav__category {
    .category__list {
      padding: 0 18px 22px 15px;
      li {
        a {
          font-size: 1.5rem;
          line-height: 1;
          color: #201a0a;
          display: block;
          padding: 20px 0;
          position: relative;
          &:hover {
            background: #d8efa6;
            color: $accent-color;
          }
          &::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 0 4px 10px;
            border-color: transparent transparent transparent $accent-color;
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
          }
        }
        & + li {
          border-top: 1px dashed #201a0a;
        }
      }
    }
  }
  /*---------------------------
    関連サービス
  ---------------------------*/
  .sideNav__relation {
    .relationBanner {
      padding:  10px 15px 22px;
      text-align: center;
    }
  }
  /*---------------------------
    事務所詳細
  ---------------------------*/
  .sideNav__client {
    padding: 10px 14px 19px;
    .clientTtl {
      width: calc(100% + 25px);
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.66666;
      color: #fff;
      padding: 9px 20px;
      margin-bottom: 20px;
      background: $accent-color;
      position: relative;
      top: 0;
      left: -25px;
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 11px 7px 0;
        border-color: transparent #113216 transparent transparent;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: -7px;
      }
    }
    .Thumb {
      display: block;
      margin: 0 auto;
    }
    .tel {
      border: 1px solid $accent-color;
      border-radius: 2px;
      background: #fff;
      padding: 15px 20px 12px 60px;
      margin: 24px 0 0;
      position: relative;
      address {
        font-size: 2.5rem;
        font-style: normal;
        color: $font-color-base;
      }
      p {
        font-size: 1.2rem;
        line-height: 1.3;
        margin: 5px 0 0;
      }
      &::before {
        content: "";
        width: 25px;
        height: 25px;
        background: url(/images/icon/icon_tel.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        vertical-align: baseline;
        position: absolute;
        top: 20px;
        left: 20px;
      }
      &:hover {
        &::before {
          animation-duration: .3s;
          animation-timing-function: liner;
          animation-name: vibrate;
        }
        address {
          color: $accent-color;
        }
      }
    }
    .btn--mailForm {
      font-size: 1.6rem;
      line-height: 1;
      text-shadow: 0px 1px 1px rgba(21, 15, 15, 0.21);
      color: #fff;
      padding: 14px 19px;
      display: block;
      background: #86c016;
      border-radius: 2px;
      text-align: center;
      margin: 14px 0 0;
      &::before {
        content: "";
        width: 16px;
        height: 13px;
        background: url(/images/icon/icon_mail.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        margin-right: 17px;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
}

@media screen and (max-width: $display-width-s){
/* =================================
  side nav
================================= */
.sideNav {
  /*---------------------------
    サイドナビ共通
  ---------------------------*/
  margin: 0;
  &__inner {
    padding: 15px 20px;
  }
  &__ttl {
    font-size: 1.3rem;
    color: $accent-color;
    background: #faf9f6;
    border: 2px solid $accent-color;
    padding: 17px 15px 17px 13px;
    display: block;
    position: relative;
    top: 0;
    &::before {
      width: 2px;
      height: 14px;
      background: $accent-color;
      position: absolute;
      top: 50%;
      left: initial;
      right: 18px;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      width: 14px;
      height: 2px;
      background: $accent-color;
      position: absolute;
      top: 50%;
      left: initial;
      right: 12px;
      transform: translateY(-50%);
    }
  }
  &__sec {
    background: #fff;
    & + .sideNav__sec {
      margin: 0 0 10px;
    }
    &.active {
      .sideNav__ttl::before {
        content: none;
      }
    }
  }
  /*---------------------------
    検索
  ---------------------------*/
  .sideNav__search {
    display: none;
  }
  /*---------------------------
    よく読まれている記事
  ---------------------------*/
  .popular__list {
    padding: 0 15px 10px;
    margin: 10px 0 0;
    background: #faf9f6;
    display: none;
    li {
      position: relative;
      a {
        font-size: 1.4rem;
        padding: 10px 6px 10px 45px;
      }
      &::before {
        font-size: 1.4rem;
        padding: 8px 5px;
      }
    }
  }
  /*---------------------------
    カテゴリー
  ---------------------------*/
  .sideNav__category {
    .category__list {
      padding: 0 15px 10px;
      margin: 10px 0 0;
      background: #faf9f6;
      display: none;
      li {
        a {
          font-size: 1.5rem;
          padding: 20px 0;
        }
      }
    }
  }
  /*---------------------------
    関連サービス
  ---------------------------*/
  .sideNav__relation {
    .sideNav__ttl {
      display: none;
    }
    .relationBanner {
      padding: 0;
      margin: 20px 0 0;
      a {
        display: block;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }










  /*---------------------------
    事務所詳細
  ---------------------------*/
  .sideNav__client {
    display: none;
    padding: 10px 14px 19px;
    .clientTtl {
      width: calc(100% + 25px);
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.66666;
      color: #fff;
      padding: 9px 20px;
      margin-bottom: 20px;
      background: $accent-color;
      position: relative;
      top: 0;
      left: -25px;
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 11px 7px 0;
        border-color: transparent #113216 transparent transparent;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: -7px;
      }
    }
    .Thumb {
      display: block;
      margin: 0 auto;
    }
    .tel {
      border: 1px solid $accent-color;
      border-radius: 2px;
      background: #fff;
      padding: 15px 20px 12px 60px;
      margin: 24px 0 0;
      position: relative;
      address {
        font-size: 2.5rem;
        font-style: normal;
        color: $font-color-base;
      }
      p {
        font-size: 1.2rem;
        line-height: 1.3;
        margin: 5px 0 0;
      }
      &::before {
        content: "";
        width: 25px;
        height: 25px;
        background: url(/images/icon/icon_tel.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        vertical-align: baseline;
        position: absolute;
        top: 20px;
        left: 20px;
      }
      &:hover {
        &::before {
          animation-duration: .3s;
          animation-timing-function: liner;
          animation-name: vibrate;
        }
        address {
          color: $accent-color;
        }
      }
    }
    .btn--mailForm {
      font-size: 1.6rem;
      line-height: 1;
      text-shadow: 0px 1px 1px rgba(21, 15, 15, 0.21);
      color: #fff;
      padding: 14px 19px;
      display: block;
      background: #86c016;
      border-radius: 2px;
      text-align: center;
      margin: 14px 0 0;
      &::before {
        content: "";
        width: 16px;
        height: 13px;
        background: url(/images/icon/icon_mail.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        margin-right: 17px;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
}

}
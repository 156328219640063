@charset "utf-8";

/*=================================
  footer
=================================*/
.footer {
  background: #2b3942;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .fNav {
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      padding: 50px 0 34px;
      border-bottom: 1px solid #414d55;
      .fNav__list {
        display: flex;
        line-height: 1;
        .fNav__ttl {
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1;
          color: #fff;
          padding-bottom: 3px;
          margin: 0 0 30px;
          border-bottom: 1px dotted #aab0b3;
          display: inline-block;
        }
        &__item {
          a {
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1;
            color: #fff;
            padding-left: 17px;
            display: inline-block;
            @extend %hoverUnder;
            &::before {
              content: "";
              border-style: solid;
              border-width: 4.5px 0 4.5px 9px;
              border-color: transparent transparent transparent #fff;
              display: inline-block;
              margin-right: 7px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
        > li {
          &+ li {
            margin-left: 100px;
          }
          li + li {
            margin: 16px 0 0;
          }
        }
      }
      .sns {
        display: flex;
        margin-right: 70px;
        li {
          background: #414d55;
          border-radius: 50%;
          a {
            font-size: 1.6rem;
            color: #fff;
            width: 34px;
            line-height: 34px;
            text-align: center;
            @include animation-base;
          }
          & + li {
            margin-left: 17px;
          }
          &:hover {
            a {
              transform: rotateY(360deg);
            }
          }
        }
      }
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      padding: 33px 0;
      .fNav__list {
        display: flex;
        a {
          font-size: 1.2rem;
          color: #fff;
          @extend %hoverUnder;
        }
        li + li {
          margin-left: 28px;
        }
      }
    }
  }
  .copy {
    font-size: 1.2rem;
    line-height: 1;
    color: #fff;
    padding-right: 15px;
  }
}
.pageTop {
  position: fixed;
  right: calc((100% - 1300px) / 2);
  bottom: 36px;
  .btn--pageTop {
    background: #3b4a53;
    padding: 30px;
    display: block;
    &::before {
      content: "";
      border-style: solid;
      border-width: 0 6px 12px 6px;
      border-color: transparent transparent #ffffff transparent;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @include animation-base;
    }
  }
  &:hover {
    opacity: 0.8;
    .btn--pageTop {
      &::before {
        top: 45%;
      }
    }
  }
}
@media screen and (max-width: 1320px){
  .pageTop {
    right: 0;
  }
  .footer .copy {
    padding-right: 65px;
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    footer
  =================================*/
  .footer {
    .fNav {
      &__top {
        display: block;
        padding: 40px 26px 27px 36px;
        border-bottom: none;
        .fNav__list {
          display: block;
          .fNav__ttl {
            font-size: 1.5rem;
            padding-bottom: 6px;
            margin: 0 0 19px;
          }
          &__item {
            a {
              font-size: 1.3rem;
              position: relative;
              &::before {
                content: "";
                border-style: solid;
                border-width: 4.5px 0 4.5px 9px;
                border-color: transparent transparent transparent #fff;
                display: inline-block;
                margin-right: 7px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }
          }
          > li {
            &+ li {
              margin: 38px 0 0;
            }
            li + li {
              margin: 13px 0 0;
            }
          }
        }
        .sns {
          display: flex;
          justify-content: center;
          margin: 42px 0 0;
          li {
            a {
              font-size: 1.5rem;
            }
            & + li {
              margin-left: 13px;
            }
          }
        }
      }
      &__bottom {
        display: block;
        padding: 0 0 31px;
        .fNav__list {
          justify-content: center;
          a {
            font-size: 1rem;
          }
          li + li {
            margin-left: 21px;
          }
        }
      }
    }
    .copy {
      font-size: .9rem;
      display: block;
      text-align: center;
      margin: 28px 0 0;
      padding-right: 0!important;
    }
  }
  .pageTop {
    right: 5px!important;
    bottom: 5px;
    .btn--pageTop {
      padding: 22px;
      opacity: .8;
      &::before {
        border-width: 0 5.5px 9px 5.5px;
      }
    }
  }
}
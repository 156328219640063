@charset "utf-8";
/* =================================
  toppage
================================= */
/*mv*/
.mv {
  background: #dbeff8;
  padding: 30px 0 90px;
  &__inner {
    max-width: 1200px;
    margin: 0 auto;
  }
  .sliderCard {
    .thumb {
      position: relative;
      .label {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__ttl {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.55555;
      letter-spacing: .02em;
      margin: 10px 0 0;
    }
    &__text {
      margin: 18px 0 0;
      time {
        font-size: 1.6rem;
        font-weight: bold;
        color: #000;
        border-bottom: 1px dotted #000;
      }
      > span {
        font-size: 1.6rem;
        font-weight: bold;
        color: #000;
        border-bottom: 1px dotted #000;
        margin-left: 15px;
        span {
          font-size: 1.2rem;
        }
      }
    }
    &__in {
      margin: 0 10px;
    }
  }
}
.topSecWrap {
  /*recommend*/
  &.reco {
    background: url(/images/bg_geometry_l.png) no-repeat left center,
                url(/images/bg_geometry_r.png) no-repeat right center,
                #fff;
    .reco__ttl {
      text-align: center;
      position: relative;
      top: -36px;
      &Sub {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1;
        text-align: center;
      }
    }
    .recoList {
      max-width: $base-width;
      margin: 64px auto 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc(33.333333% - 13.333333px);
        height: 225px;
        text-align: center;
        background: $accent-color;
        margin: 0 0 20px 20px;
        position: relative;
        cursor: pointer;
        a {
          font-size: 1.8rem;
          font-weight: bold;
          color: #fff;
          padding: 0 0 0 18px;
          position: absolute!important;
          left: 50%;
          bottom: 40px;
          transform: translateX(-50%);
          span {
            &::before {
              content: "";
              border-style: solid;
              border-width: 4.5px 0 4.5px 9px;
              border-color: transparent transparent transparent #fff;
              display: inline-block;
              margin-right: 7px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
          &::after {
            content: "";
            width: 0;
            height: 1px;
            background: #fff;
            display: inline-block;
            position: absolute;
            bottom: -3px;
            left: 0;
            @include animation-base;
          }
        }
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
        &:nth-child(1) {
          span::after {
            content: "";
            width: 39px;
            height: 51px;
            background: url(/images/icon/icon_beginner.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: -65px;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        &:nth-child(2) {
          span::after {
            content: "\f2b5";
            font-family: "FontAwesome";
            font-size: 5rem;
            font-weight: normal;
            color: #fff;
            display: inline-block;
            position: absolute;
            top: -65px;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        &:nth-child(3) {
          span::after {
            content: "\f0d6";
            font-family: "FontAwesome";
            font-size: 5rem;
            font-weight: normal;
            color: #fff;
            display: inline-block;
            position: absolute;
            top: -65px;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        &:nth-child(4) {
          span::after {
            content: "\f080";
            font-family: "FontAwesome";
            font-size: 5rem;
            font-weight: normal;
            color: #fff;
            display: inline-block;
            position: absolute;
            top: -65px;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        &:nth-child(5) {
          span::after {
            content: "\f0c0";
            font-family: "FontAwesome";
            font-size: 5rem;
            font-weight: normal;
            color: #fff;
            display: inline-block;
            position: absolute;
            top: -65px;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        &:nth-child(6) {
          span::after {
            content: "\f155";
            font-family: "FontAwesome";
            font-size: 5rem;
            font-weight: normal;
            color: #fff;
            display: inline-block;
            position: absolute;
            top: -65px;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          background: url(/images/deco_frame.png) no-repeat center center;
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          opacity: 0;
          transform: scale(.9);
          @include animation-base;
        }
        &:hover {
          &::before {
            opacity: 1;
            transform: scale(1);
          }
          a {
            text-decoration: none;
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
  /*brand*/
  &.brand {
    background: url(/images/top_brandBg.jpg) no-repeat center center;
    padding: 38px 0 40px;
    .brand__ttl {
      font-size: 3.4rem;
      font-weight: bold;
      font-style: italic;
      color: $accent-color-dark;
      background: url(/images/top_brandTtl.png) no-repeat right top;
      background-size: contain;
      padding: 14px 0 0;
      span {
        font-size: 1.4rem;
        font-weight: normal;
        font-style: normal;
        color: #000;
        margin-left: 28px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .btn--base {
      font-size: 2rem;
      max-width: 422px;
      margin: 0 auto;
    }
  }
  .brandList {
    display: flex;
    flex-wrap: wrap;
    margin: 17px auto 30px;
    &__item {
      width: calc(20% - 16px);
      background: #fff;
      box-shadow: 0px 3px 5px 0px rgba(43, 57, 66, 0.05);
      margin: 20px 0 0 20px;
      text-align: center;
      padding: 30px 0 26px;
      &:nth-child(5n + 1) {
        margin-left: 0;
      }
      .banner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        position: relative;
      }
      a {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1;
        color: $accent-color;
        display: inline-block;
        position: relative;
        &::before {
          content: "";
          border-style: solid;
          border-width: 4.5px 0 4.5px 9px;
          border-color: transparent transparent transparent $accent-color;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
  /*pickup*/
  &.pickup {
    padding: 64px 0 66px;
    .pickup__box {
      display: flex;
      justify-content: space-between;
      > * {
        width: calc(50% - 31px);
      }
      .articleCard {
        display: flex;
        padding: 20px 0 12px;
        border-bottom: 1px solid #bababa;
      }
    }
    .newpost {
      .titleWrap {
        background: url(/images/newpost_ttl.png) no-repeat right center;
        margin: 0 0 25px;
        padding: 5px 0;
      }
      .newpost__ttl {
        font-size: 3rem;
        font-weight: bold;
        font-style: italic;
        color: $accent-color-dark;
        position: relative;
        &::after {
          content: "";
          width: 2em;
          height: 4px;
          display: inline-block;
          background: #dae7ed;
          position: absolute;
          left: 0;
          bottom: -20px;
        }
      }
    }
    .popular {
      .titleWrap {
        background: url(/images/popular_ttl.png) no-repeat right center;
        margin: 0 0 25px;
        padding: 5px 0;
      }
      .popular__ttl {
        font-size: 3rem;
        font-weight: bold;
        font-style: italic;
        color: $accent-color-dark;
        position: relative;
        &::after {
          content: "";
          width: 2em;
          height: 4px;
          display: inline-block;
          background: #dae7ed;
          position: absolute;
          left: 0;
          bottom: -20px;
        }
      }
    }
  }
  /*column*/
  &.column {
    padding: 80px 0 72px;
    background: #395668;
    .titleWrap {
      margin: 0 0 22px;
    }
    .column__ttl {
      font-size: 2.4rem;
      font-weight: bold;
      color: #fff;
    }
    .allview {
      color: $accent-color;
      background: #fff;
      border: none;
      &::before {
        border-color: transparent transparent transparent $accent-color;
      }
      &:hover {
        color: #fff;
        background: $accent-color;
        &::before {
          border-color: transparent transparent transparent #fff;
        }
      }
    }
  }
}
.column__2col {
  display: flex;
  justify-content: space-between;
  .columnBox {
    width: calc(50% - 31px);
  }
  .articleCard {
    margin: 19px 0 0;
  }
  & + .column__2col {
    margin: 60px 0 0;
  }
}
.allview:hover {
  background: #fff;
}
.articleCard__text {
  padding: 18px 10px 18px 0;
}

/* =================================
  article list
================================= */
/*tab*/
.ui-widget.ui-widget-content {
  border: none;
  border-radius: 0;
  padding: 0;
  background: initial;
}
.ui-tabs .ui-tabs-nav {
  padding: 0;
  border-radius: 0;
  border: none;
  background: none;
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  display: block;
  text-align: center;
  float: none;
}
.ui-tabs .ui-tabs-panel {
  padding: 52px 0 0;
}
/*tabs Num*/
#listNum .ui-state-active, #listNum .ui-widget-content .ui-state-active, #listNum .ui-widget-header .ui-state-active, #listNum a.ui-button:active, #listNum .ui-button:active, #listNum .ui-button.ui-state-active:hover {
  background: $accent-color!important;
}
#listNum .ui-state-active a {
  color: #fff!important;
}
#listNum .ui-state-default, #listNum .ui-widget-content .ui-state-default, #listNum .ui-widget-header .ui-state-default, #listNum .ui-button, #listNum html .ui-button.ui-state-disabled:hover, #listNum html .ui-button.ui-state-disabled:active {
 background: #fff;
}
#listNum.ui-tabs .ui-tabs-nav li {
  width: 50%;
  margin: 0;
  padding: 0;
  border: 1px solid $accent-color;
  border-radius: 0;
  position: relative;
  a {
    font-size: 2rem;
    font-weight: bold;
    color: $accent-color;
  }
  &:not(.ui-tabs-active):hover {
    background: $accent-color;
    opacity: .8;
    a {
      color: #fff;
    }
  }
  &:first-child {
    border-right: none;
  }
  & + li {
    border-left-color: #fff;
  }
}
.pageSummary {
  line-height: 1.555555;
  margin: 30px 0 50px;
}
.articleListWrap {
  margin: 0 0 40px;
}
.articleList {
  margin: 26px 0 38px;
  li {
    display: flex;
    align-items: flex-start;
    border: 1px solid #bababa;
    .thumb {
      position: relative;
      width: 310px;
      .label {
        position: absolute;
        top: 0;
        left: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    & + li {
      margin: 22px 0 0;
    }
    &:hover a {
      text-decoration: none!important;
    }
  }
  &__item {
    width: calc(100% - 310px);
    padding: 20px 30px;
    background: #fff;
    .articleList__ttl a {
      font-size: 2.2rem;
      line-height: 1.454545;
      color: $accent-color;
      text-decoration: underline;
    }
    .articleList__text {
      font-size: 1.5rem;
      line-height: 1.75;
      margin: 15px 0 0;
    }
  }
}
.pagenation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $base-width-in;
  margin: 0 auto 0;
  .pager {
    display: flex;
    li {
      font-size: 2.4rem;
      line-height: 1;
      display: inline-block;
      a {
        color: #494848;
        background: #eee;
        display: block;
        padding: 18px 23px;
        &:hover {
          color: $accent-color-dark;
          background: #dfedf4;
        }
      }
      span {
        color: $accent-color-dark;
        background: #dfedf4;
        display: block;
        padding: 18px 23px;
      }
      & + li {
        margin-left: 2px;
      }
    }
  }
  .listCount {
    font-size: 2rem;
    color: $accent-color-dark;
  }
}
/* =================================
  singlePage
================================= */
.singlePage {
  width: 100%;
  background: #fff;
  border: 1px solid #bababa;
  border-top: none;
  margin: 0 0 70px;
  position: relative;
  &::before {
    content: "";
    width: calc(100% + 2px);
    height: 5px;
    display: block;
    background: $accent-color-dark;
    margin-left: -1px;
  }
  .data {
    margin: 30px 0 25px;
    .time {
      margin-left: 24px;
    }
    .view {
      margin-left: 12px;
    }
  }
  .articleMv {
    width: 860px;
    height: auto;
    margin: 25px auto 30px;
  }
  .tocBox {
    background: #eef4f6;
    margin: 28px 0 70px;
    &__ttl {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      background: $accent-color-dark;
      display: inline-block;
      margin: 0;
      padding: 8px 12px 8px 20px;
      position: relative;
      vertical-align: bottom;
      &::before {
        content: "";
        width: 13px;
        height: 100%;
        background: $accent-color-dark;
        display: inline-block;
        position: absolute;
        top: 0;
        right: -6px;
        -webkit-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        transform: skew(-20deg);
      }
    }
    .tocList {
      padding: 30px 36px 40px;
      counter-reset: counterToc;
      &__item {
        padding: 0 0 0 33px;
        position: relative;
        a {
          font-size: 1.6rem;
          text-decoration: underline;
          &:hover {
            color: $accent-color;
            text-decoration: none;
          }
        }
        & + .tocList__item {
          margin: 12px 0 0;
        }
        &::before {
          content: "0" counter(counterToc);
          counter-increment: counterToc;
          font-size: 1.8rem;
          color: $accent-color;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  &__in {
    padding: 0 80px 0;
    .aligncenter {
      display: block;
      margin: 36px auto 20px;
    }
    & > {
      h1 {
        font-size: 4rem;
        font-weight: bold;
        line-height: 1.3;
        color: $accent-color-dark;
        margin: 67px 0 30px;
      }
      h2 {
        font-size: 3.2rem;
        font-weight: normal;
        line-height: 1.375;
        color: #fff;
        background: $accent-color-dark;
        padding: 22px 25px;
        margin: 67px 0 35px;
      }
      h3 {
        font-size: 2.8rem;
        font-weight: normal;
        line-height: 1.2857;
        border-bottom: 1px dotted #030000;
        border-left: 6px solid $accent-color-dark;
        padding: 5px 13px 15px;
        margin: 50px 0 25px;
      }
      h4 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.8;
        margin: 50px 0 38px;
        padding-bottom: 20px;
        border-bottom: 1px solid #969696;
        position: relative;
        &::before {
          content: "";
          width: 250px;
          height: 2px;
          display: inline-block;
          background: $accent-color-dark;
          position: absolute;
          bottom: -2px;
          left: 0;
        }
      }
      img {
        display: block;
        margin: 36px auto 20px;
      }
      .imgBox--r {
        overflow: hidden;
        img {
          float: right;
          max-width: 400px;
          height: auto;
          margin: 0 0 20px 40px;
        }
        p {
          margin-top: 0;
        }
      }
      .imgBox--l {
        overflow: hidden;
        img {
          float: left;
          max-width: 400px;
          height: auto;
          margin: 0 40px 20px 0;
        }
        p {
          margin-top: 0;
        }
      }
      blockquote {
        position: relative;
        padding: 35px 15px 10px 15px;
        box-sizing: border-box;
        font-style: italic;
        background: #f5f5f5;
        color: #777777;
        border-left: 4px solid #0a4098;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
      }
      blockquote:before{
        content: "\f10d";
        font-family: "FontAwesome";
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 15px;
        vertical-align: middle;
        color: #0a4098;
        font-size: 28px;
        line-height: 1;
      }
      blockquote p {
        padding: 0;
        margin: 7px 0;
        font-size: 1.6rem;
        line-height: 1.666666;
      }
      blockquote cite {
        display: block;
        text-align: right;
        color: #888888;
        font-size: 1.4rem;
      }
      ul {
        margin: 30px auto 70px;
        li {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.35;
          position: relative;
          padding-left: 34px;
          &::before {
            content: "";
            width: 12px;
            height: 12px;
            background: #1d51a6;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 7px;
          }
          &+ li {
            margin: 20px 0 0;
          }
        }
      }
      ol {
        counter-reset: counterOl;
        margin: 40px auto 45px;
        li {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.35;
          position: relative;
          padding-left: 34px;
          &::before {
            content: counter(counterOl) ".";
            counter-increment: counterOl;
            font-weight: bold;
            line-height: 1;
            color: $accent-color;
            display: inline-block;
            position: absolute;
            left: 9px;
            top: 4px;
          }
          &+ li {
            margin: 20px 0 0;
          }
        }
      }
    }
  }
  .related--sm {
    display: flex;
    margin: 45px 0;
    .related__ttl {
      font-size: 1.4rem;
      font-weight: bold;
      color: #fff;
      line-height: 1;
      padding: 9px 6px 9px 10px;
      background: $accent-color;
      display: inline-block;
      position: relative;
      &::before {
        content: "";
        width: 12px;
        height: 100%;
        background: $accent-color;
        display: inline-block;
        position: absolute;
        top: 0;
        right: -6px;
        transform: skew(-20deg);
      }
    }
    a {
      margin-left: 30px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    p {
      margin: 0;
    }
  }
  .related--md {
    border: 1px solid #4373c2;
    padding: 10px;
    margin: 45px 0 40px;
    position: relative;
    .related__ttl {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      background: $accent-color;
      padding: 7px 9px;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      &::before {
        content: "";
        width: 12px;
        height: 100%;
        background: $accent-color;
        display: inline-block;
        position: absolute;
        top: 0;
        left: -6px;
        transform: skew(20deg);
      }
    }
    .related__list__item {
      display: flex;
      justify-content: space-between;
      .thumb {
        width: 240px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .related__txt {
        width: calc(100% - 252px);
        margin-left: 12px;
        a {
          font-size: 2rem;
          line-height: 1.6;
          text-decoration: underline;
        }
      }
      .data {
        margin: 18px 0;
      }
      .time {
        margin-left: 0;
      }
      & + .related__list__item {
        margin: 10px 0 0;
      }
    }
  }
  .related--lg {
    padding: 60px 80px 75px;
    margin: 76px auto 0;
    background: #496c81;
    .related__ttl {
      font-size: 3.2rem;
      margin: 0 0 65px;
    }
    .related__list__item {
      display: flex;
      justify-content: space-between;
      .thumb {
        width: 330px;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        .label {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .related__txt {
        width: calc(100% - 330px);
        background: #fff;
        padding: 30px;
        a {
          font-size: 2rem;
          line-height: 1.5;
        }
      }
      .data {
        margin: 0 0 20px;
      }
      .time {
        margin-left: 0;
      }
      & + .related__list__item {
        margin: 28px 0 0;
      }
    }

  }
  .pointBox {
    background: #fff6f6;
    padding: 48px 46px 33px;
    margin: 45px 0 20px;
    position: relative;
    &__ttl {
      font-size: 2.4rem;
      font-weight: normal;
      line-height: 1;
      color: #e71d0e;
      display: inline-block;
      &__sub {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        background: #ed3b3b;
        padding: 7px 9px;
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        &::after {
          content: "";
          width: 12px;
          height: 100%;
          background: #ed3b3b;
          display: inline-block;
          position: absolute;
          top: 0;
          left: -6px;
          transform: skew(20deg);
        }
      }
    }
  }
  .pager {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 37px;
    .prev,.next {
      width: 50%;
      display: flex;
      align-items: center;
      span {
        font-size: 1.4rem;
        font-weight: bold;
        color: #fff;
        background: $accent-color;
        width: 100px;
        text-align: center;
        padding: 24px 0;
      }
      a {
        width: calc(100% - 100px);
        font-size: 1.6rem;
        line-height: 1.625;
        color: #000;
        padding: 9px 22px;
      }
    }
    .prev {
      span::before {
        content: "";
        border-style: solid;
        border-width: 5px 10px 5px 0;
        border-color: transparent #fff transparent transparent;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }
    }
    .next {
      display: flex;
      flex-direction: row-reverse;
      border-left: 1px dotted #363333;
      span::after {
        content: "";
        border-style: solid;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent #fff;
        display: inline-block;
        vertical-align: middle;
        margin-left: 7px;
      }
    }
  }
  .fbShareBox {
    display: flex;
    margin: 37px 0 76px;
    .thumb {
      width: 50%;
    }
    .text {
      text-align: center;
      width: 50%;
      
      background: $accent-color-dark;
      padding: 23px 0;
      p {
        font-size: 2.4rem;
        line-height: 1.66666;
        color: #fff;
        margin: 28px 0 0;
      }
    }
    .fbBtnWrap {
      background: #fff;
      display: inline-block;
      padding: 0 3px;
      margin: 30px 0 0;
      > div {
        line-height: 1;
      }
    }
  }
}
.outBox {
  width: calc(100% + 160px);
  position: relative;
  left: -80px;
}
/*おすすめ*/
.recommend {
  background: #eef4f6;
  padding: 65px 80px 60px;
  &__ttl {
    font-size: 3.2rem;
    font-weight: normal;
    color: #fff;
    background: $accent-color-dark;
    padding: 24px;
    margin: 0 0 33px;
    &__sub {
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1;
      margin: 0 0 33px;
      a {
        display: inline-block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .topParts {
    display: flex;
    align-items: flex-start;
    .recommend {
      &__thumb{
        width: 320px;
        height: auto;
        margin: 0 20px 0 0;
      }
      &__table {
        width: calc(100% - 340px);
        table {
          margin: 0;
        }
        caption {
          font-size: 1.6rem;
          color: #fff;
          padding: 7px 0;
          background: $accent-color-dark;
        }
        th {
          font-size: 1.3rem;
          font-weight: bold;
          color: #050505;
          text-align: center;
          background: #f5f5f5;
          border: none;
          padding: 7px 5px;
          & + th {
            border-left: 2px solid #dedede;
          }
        }
        td {
          font-size: 1.6rem;
          font-weight: bold;
          text-align: center;
          background: #fff;
          border: none;
          padding: 4px 5px;
          & + td {
            border-left: 2px solid #dedede;
          }
        }
        .basicInfo {
          td {
            color: #e71d0e;
          }
        }
      }
    }
  }
  .summary{
    font-size: 1.6rem;
    line-height: 2;
  }
  .campaign {
    background: #fff;
    padding: 37px 33px;
    position: relative;
    &__ttl {
      font-size: 1.4rem;
      font-weight: bold;
      color: #fff;
      background: #ed3b3b;
      padding: 5px 9px;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      &::after {
        content: "";
        width: 12px;
        height: 100%;
        background: #ed3b3b;
        display: inline-block;
        position: absolute;
        top: 0;
        left: -6px;
        -webkit-transform: skew(20deg);
        -ms-transform: skew(20deg);
        transform: skew(20deg);
      }
      &__sub {
        font-size: 2.6rem;
        color: #e71d0e;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2;
      margin: 20px 0 0;
    }
  }
}
.underSecWrap {
  background: #eef4f6;
  padding: 50px 0 70px;
  .titleWrap {
    margin: 0 0 45px;
  }
  .column__ttl {
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    color: #0d3781;
    position: relative;
    &::after {
      content: "";
      width: 2em;
      height: 4px;
      display: inline-block;
      background: #dae7ed;
      position: absolute;
      left: 0;
      bottom: -20px;
    }
  }
  .articleList--2col .articleCard {
    background: transparent;
    border-bottom: 1px solid #bababa;
    padding-bottom: 12px;
    .articleCard__text {
      padding: 0;
    }
  }
}
.article__detail {
  max-width: 1020px;
}
.snsBarWrap {
  width: 60px;
}
.snsBar {
  margin-top: 50px;
  li {
    text-align: center;
    a {
      font-size: 2rem;
      color: #fff;
      padding: 14px 20px;
      display: block;
      img {
        display: inline-block;
        vertical-align: middle;
      }
    }
    &.tw {
      background: #33addc;
    }
    &.fb {
      background: #3d5184;
    }
    &.hateb {
      background: #2785cb;
    }
    &.line {
      background: #67bb49;
    }
    &.rss {
      background: #fd982e;
    }
    & + li {
      border-top: 1px solid #fff;
    }
    &:hover {
      a {
        transform: rotateY(360deg);
      }
    }
  }
}
/* =================================
  FX会社一覧
================================= */
.clientListWrap {
  margin: 50px 0 60px;
}
.clientList {
  margin: 30px 0 56px;
}
.listCard {
  border: 1px solid #bababa;
  border-top: 4px solid $accent-color-dark;
  background: #fff;
  padding: 40px 60px 60px;
  position: relative;
  &__ttl {
    font-size: 3.4rem;
    line-height: 1;
    margin: 0 0 30px;
  }
  &__top {
    display: flex;
    .thumb {
      width: 424px;
      margin: 0 26px 0 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    .table--feature {
      width: calc(100% - 432px);
      margin: -2px 0 0;
      li {
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.14285;
        width: 112px;
        height: 94px;
        background: #eef4f6;
        display: inline-block;
        vertical-align: middle;
        padding: 57px 0 0;
        margin: 2px 0 0 -3px;
        position: relative;
        .icon {
          font-size: 2rem;
          color: $accent-color-dark;
          line-height: 1;
          display: inline-block;
          position: absolute;
          top: 26px;
          left: 50%;
          transform: translateX(-50%);
          &.sm {
            font-size: 2.2rem;
          }
          &.md {
            font-size: 2.4rem
          }
          &.lg {
            font-size: 2.8rem;
          }
        }
      }
    }
  }
  .tableWrap {
    display: flex;
    margin: 28px 0 23px;
    table {
      border-bottom: 2px solid #dedede;
      margin: 0;
    }
    caption {
      font-size: 1.8rem;
      color: #fff;
      background: $accent-color-dark;
      padding: 8px 0;
    }
    th {
      font-size: 1.4rem;
      font-weight: bold;
      color: #050505;
      text-align: center;
      background: #eef4f6;
      padding: 10px 0;
      border: none;
      border-left: 2px solid #dedede;
      &:first-child {
        border: none;
      }
    }
    td {
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      background: #fff;
      padding: 12px 0;
      border: none;
      border-left: 2px solid #dedede;
      &:first-child {
        border: none;
      }
    }
    .table--basicinfo {
      width: 376px;
    }
    .table--spread {
      width: calc(100% - 376px);
      border-left: 2px solid #dedede;
      caption {
        border-left: 2px solid #fff;
      }
    }
  }
  .desc {
    margin: 23px 0;
  }
  .campaign {
    background: #fff6f6;
    padding: 40px 46px 35px;
    &__ttl {
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 1;
      color: #e71d0e;
      margin: 0 0 18px;
    }
    &__text {
      margin: 0;
    }
  }
  & + .listCard {
    margin: 25px 0 0;
  }
  .tag {
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;
    background: $accent-color;
    padding: 5px 25px;
    display: inline-block;
    position: absolute;
    top: -4px;
    right: -1px;
    &::before {
      content: "";
      width: 12px;
      height: 100%;
      background: $accent-color;
      display: inline-block;
      position: absolute;
      top: 0;
      left: -6px;
      -webkit-transform: skew(20deg);
      -ms-transform: skew(20deg);
      transform: skew(20deg);
    }
  }
}

/* =================================
  FX会社詳細
================================= */
.clientDetail {
  &__ttl {
    font-size: 4rem;
    color: $accent-color-dark;
    font-weight: bold;
    line-height: 1;
    display: inline-block;
    margin: 0 0 42px;
  }
  &Nav {
    display: flex;
    margin: 0 0 34px;
    li {
      width: 33.333333%;
      a {
        font-size: 2rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        background: $accent-color-dark;
        padding: 18px 0;
        display: block;
        position: relative;
        &::after {
          content: "";
          border-style: solid;
          border-width: 10px 6px 0 6px;
          border-color: #fff transparent transparent transparent ;
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
          @include animation-base;
        }
        &:hover {
          background: #5673a7;
          &::after {
            top: 55%;
          }
        }
      }
      & + li {
        border-left: 1px solid #5673a7;
      }
    }
  }
  .desc__top {
    display: flex;
    margin: 0 0 42px;
    .thumb {
      width: 320px;
      margin: 0 40px 0 0;
      .caption {
        margin-bottom: 0;
        background: #fff;
      }
    }
    .descTableWrap {
      width: calc(100% - 360px);
      .table--feature {
        margin: -2px 0 0;
        li {
          font-size: 1.6rem;
          font-weight: bold;
          text-align: center;
          line-height: 1.14285;
          width: 130px;
          height: 108px;
          background: #eef4f6;
          display: inline-block;
          vertical-align: middle;
          padding: 65px 0 0;
          margin: 2px 0 0 -3px;
          position: relative;
          .icon {
            font-size: 2.3rem;
            color: $accent-color-dark;
            line-height: 1;
            display: inline-block;
            position: absolute;
            top: 26px;
            left: 50%;
            transform: translateX(-50%);
            &.sm {
              font-size: 2.5rem;
            }
            &.md {
              font-size: 2.7rem
            }
            &.lg {
              font-size: 3.2rem;
            }
          }
        }
      }
      .descTable__ttl {
        font-size: 2rem;
        font-weight: normal;
        line-height: 30px;
        border-left: 6px solid $accent-color-dark;
        padding-left: 14px;
        margin: 16px 0 9px;
      }
      .table--basicInfo {
        display: flex;
        border: 1px solid #3d5c92;
        background: #fff;
        padding: 15px 20px;
        dl {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // width: 33.333333%;
          position: relative;
          & + dl {
            padding-left: 53px;
            &::before {
              content: "/";
              font-size: 1.8rem;
              color: $accent-color-dark;
              position: absolute;
              top: 50%;
              left: 25px;
              transform: translateY(-50%);
            }
          }
          dt {
            font-size: 1.6rem;
            font-weight: bold;
          }
          dd {
            font-size: 1.8rem;
            font-weight: bold;
            color: #c41928;
            margin-left: 20px;
          }
        }
      }
      .table--spread {
        display: flex;
        justify-content: space-between;
        dl {
          width: 120px;
          height: 120px;
          background: #eef4f6;
          border-radius: 50%;
          text-align: center;
          dt {
            font-size: 1.4rem;
            font-weight: bold;
            &::before {
              content: "";
              width: 47px;
              height: 17px;
              display: block;
              margin: 19px auto 10px;
            }
            &.usd::before {
              background: url(/images/icon/icon_usd.png) no-repeat center center;
              background-size: contain;
            }
            &.eur::before {
              background: url(/images/icon/icon_eur.png) no-repeat center center;
              background-size: contain;
            }
            &.nzd::before {
              background: url(/images/icon/icon_nzd.png) no-repeat center center;
              background-size: contain;
            }
            &.gbp::before {
              background: url(/images/icon/icon_gbp.png) no-repeat center center;
              background-size: contain;
            }
            &.nzd::before {
              background: url(/images/icon/icon_nzd.png) no-repeat center center;
              background-size: contain;
            }
          }
          dd {
            font-size: 2.6rem;
            font-weight: bold;
            line-height: 1.3;
            span {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
  .conclusion {
    &__table {
      margin: 50px 0 42px;
      border: 4px solid #ed3b3b;
      padding: 23px;
      border-collapse: unset;
      background: #fff6f6;
    }
    dl {
      display: flex;
      align-items: flex-start;
      & + dl {
        margin: 8px 0 0;
      }
    }
    dt {
      font-size: 1.8rem;
      font-weight: bold;
      color: #fff;
      text-align: center;
      width: 210px;
      padding: 9px 0;
      background: #ed3b3b;
      position: relative;
      &::before {
        content: "\f00c";
        font-family: "FontAwesome";
        font-size: 1.8rem;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
    dd {
      font-size: 1.8rem;
      font-weight: bold;
      width: calc(100% - 210px);
      padding: 9px 20px;
    }
  }
  .clientRecommend {
    margin: 42px 0 55px;
    .recommend__ttl {
      margin: 0 0 5px;
    }
    .recommend__list {
      li {
        font-size: 2.2rem;
        border-bottom: 1px dotted #030000;
        padding: 17px 18px;
        position: relative;
        &::before {
          content: "\f2be";
          font-family: "FontAwesome";
          font-size: 2.4rem;
          color: $accent-color;
          margin-right: 16px;
        }
      }
    }
  }
  .campaign {
    background: #eef4f6;
    padding: 46px 40px 40px 50px;
    margin: 55px 0 67px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    &__ttl {
      font-size: 1.4rem;
      font-weight: bold;
      color: #fff;
      background: #0d3781;
      display: inline-block;
      padding: 8px 12px 8px 20px;
      position: absolute;
      top: 0;
      left: 0;
      &::before {
        content: "";
        width: 13px;
        height: 100%;
        background: #0d3781;
        display: inline-block;
        position: absolute;
        top: 0;
        right: -6px;
        -webkit-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        transform: skew(-20deg);
      }
    }
    .thumb {
      max-width: 300px;
      height: auto;
    }
    .campaign__text {
      margin-right: 36px;
      .campaign__ttl__sub {
        font-size: 2.8rem;
      }
      p {
        margin: 15px 0 50px;
      }
    }
    .btn--orange {
      padding: 33px 0;
    }
  }
}
.spec {
  padding: 50px 0 67px;
  background: #537386;
  caption {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    margin: 0 0 25px;
  }
  .table--spec {
    table {
      margin: 30px 0 16px;
    }
    th {
      font-size: 1.6rem;
      font-weight: bold;
      color: #000;
      text-align: center;
      padding: 12px 2px;
      background: #eef4f6;
      border: none;
      &:nth-child(n + 2) {
        border-left: 1px solid #537386;
      }
    }
    td {
      font-size: 1.6rem;
      color: #000;
      text-align: center;
      padding: 12px 2px;
      background: #fff;
      border: none;
      &:nth-child(n + 2) {
        border-left: 1px solid #537386;
      }
    }
    .notes {
      color: #fff;
      line-height: 1;
      text-align: right;
    }
  }
  .table--manageInfo {
    table {
      margin: 30px 0 16px;
    }
    tr + tr {
      border-top: 1px solid #537386;
    }
    th[colspan] {
      text-align: center;
    }
    th {
      font-size: 1.6rem;
      font-weight: bold;
      color: #000;
      background: #eef4f6;
      padding: 10px 13px;
      text-align: left;
      border: none;
    }
    td {
      background: #fff;
      padding: 10px 13px;
      text-align: left;
      width: 77%;
      border: none;
    }
    .notes {
      color: #fff;
      line-height: 1;
      text-align: right;
    }
  }
  .btnWrap {
    margin: 58px 0 0;
  }
}
.contactForm {
  tr {
    border-top: 1px solid #537386;
    &:last-child {
      border-bottom: 1px solid #537386;
    }
  }
  th {
    font-size: 1.5rem;
    font-weight: normal;
    color: #000;
    border: none;
    background: #eef4f6;
    vertical-align: top;
    padding: 30px 24px;
    width: 27%;
  }
  td {
    font-size: 1.6rem;
    color: #000;
    border: none;
    padding: 30px 20px 30px 45px;
    background: #fff;
    p {
      margin: 0;
    }
    * {
      font-size: 1.6rem;
    }
    input,textarea {
      background: #f6f9fa;
      border: 1px solid #e1e3e4;
      width: 100%;
      padding: 5px;
    }
    textarea {
      min-height: 200px;
    }
  }
  .required {
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 1;
    color: #fff;
    background: $accent-color;
    display: inline-block;
    padding: 5px 6px 5px 8px;
    margin-left: 15px;
  }
  .confirmingTxt {
    font-size: 1.4rem;
    text-align: center;
    margin: 37px 0;
    a {
      color: #000;
      text-decoration: underline;
      &:hover {
        color: $accent-color;
      }
    }
  }
  input[type="submit"] {
    margin: 0 auto;
    padding: 23px 40px;
  }
}
.completedForm {
  .completedMessage {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.06em;
    color: #d33701;
    display: inline-block;
    margin: 24px 0 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background: #d33701;
      display: inline-block;
    }
  }
  p {
    font-size: 1.5rem;
    text-align: center;
    margin: 40px 0 0;
  }
  table {
    width: initial;
    margin: 40px auto 45px;
  }
  th,td {
    width: auto;
  }
  th {
    font-size: 1.6rem;
    font-weight: bold;
    vertical-align: middle;
    padding: 15px 30px;
  }
  td {
    vertical-align: middle;
    padding: 15px 30px;
    .tel {
      address {
        font-size: 3.4rem;
        font-style: normal;
        line-height: 1;
        color: $font-color-base;
        &::before {
          content: "";
          width: 25px;
          height: 25px;
          background: url(/images/icon/icon_tel.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          vertical-align: baseline;
          margin-right: 12px;
        }
        &:hover {
          color: $accent-color;
          &::before {
            animation-duration: .3s;
            animation-timing-function: liner;
            animation-name: vibrate;
          }
        }
      }
    }
    .receptionTime {
      margin: 5px 0 0;
      dt {
        font-size: 1.3rem;
        font-weight: bold;
        color: $accent-color;
      }
      dd {
        font-size: 1.3rem;
        margin-left: 10px;
      }
      dt,dd {
        display: inline-block;
      }
    }
  }
}
@media screen and (max-width: $display-width-s){
  .mainWrap {
    margin-top: 40px;
  }
  /* =================================
    toppage
  ================================= */
  /*mv*/
  .mv {
    background: #dbeff8;
    padding: 10px 0 40px;
    .sliderCard {
      &__ttl {
        font-size: 1.4rem;
        line-height: 1.5833333;
        margin: 2px 0 0;
      }
      &__text {
        margin: 7px 0 0;
        time {
          font-size: 1.2rem;
        }
        > span {
          font-size: 1.2rem;
          margin-left: 12px;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .topSecWrap {
    /*recommend*/
    &.reco {
      background: url(/images/bg_geometry_l.png) no-repeat -155px 70px,
                  url(/images/bg_geometry_r.png) no-repeat right -140px top 70px,
                  #fff;
      background-size: contain;
      .reco__ttl {
        top: 0;
        margin: 0 0 35px;
        &Sub {
          font-size: 1.2rem;
          line-height: 1.75;
          margin: 16px 0 28px;
          span {
            display: block;
          }
        }
      }
      .recoList {
        margin: 28px auto 0;
        padding: 0 38px;
        li {
          width: calc(50% - 5px);
          height: 110px;
          margin: 0 0 10px 0;
          a {
            font-size: 1.2rem;
            padding: 0;
            bottom: 23px;
            width: 100%;
            span {
              &::before {
                margin-right: 7px;
                position: static;
                transform: translateY(0);
              }
            }
            &::after {
              content: none;
            }
          }
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:nth-child(2n) {
            margin-left: 10px;
          }
          &:nth-child(1) {
            span::after {
              width: 19px;
              height: 25px;
              top: -36px;
            }
          }
          &:nth-child(2) {
            span::after {
              font-size: 2.5rem;
              top: -36px;
            }
          }
          &:nth-child(3) {
            span::after {
              font-size: 2.5rem;
              top: -36px;
            }
          }
          &:nth-child(4) {
            span::after {
              font-size: 2.5rem;
              top: -36px;
            }
          }
          &:nth-child(5) {
            span::after {
              font-size: 2.5rem;
              top: -36px;
            }
          }
          &:nth-child(6) {
            span::after {
              font-size: 2.5rem;
              top: -36px;
            }
          }
          &::before {
            content: none;
          }
        }
      }
    }
    /*brand*/
    &.brand {
      background: url(/images/top_brandBg.jpg) no-repeat center bottom #eef4f6;
      background-size: auto 100%;
      padding: 38px 0 40px;
      .brand__ttl {
        font-size: 2.3rem;
        background: none;
        padding: 0;
        text-align: center;
        span {
          font-size: 1.2rem;
          margin-left: 0;
        }
      }
      .btn--base {
        font-size: 1.5rem;
        width: 65%;
      }
    }
    .brandList {
      margin: 25px auto 30px;
      padding: 0 38px;
      &__item {
        width: calc(50% - 5px);
        margin: 0;
        padding: 23px 5px 13px;
        &:nth-child(5n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n) {
          margin-left: 10px;
        }
        &:nth-child(n + 3) {
          margin-top: 10px;
        }
        a {
          font-size: 1.1rem;
          color: $accent-color;
          display: inline-block;
          margin-top: 8px;
          &::before {
            border-width: 4px 0 4px 8px;
            margin-right: 3px;
          }
        }
      }
    }
    /*pickup*/
    &.pickup {
      padding: 24px 38px 46px;
      .pickup__box {
        display: block;
        > * {
          width: 100%;
        }
        .articleCard {
          padding: 10px 0;
          border-bottom: 1px solid #d9d9d9;
        }
      }
      .newpost {
        .titleWrap {
          background: none;
          margin: 0 0 30px;
        }
        .newpost__ttl {
          font-size: 2.5rem;
          &::after {
            width: 2em;
            height: 3.5px;
          }
        }
      }
      .popular {
        margin: 33px 0 0;
        .titleWrap {
          background: none;
          margin: 0 0 30px;
        }
        .popular__ttl {
          font-size: 2.5rem;
          &::after {
            width: 2em;
            height: 3.5px;
          }
        }
      }
    }
    /*column*/
    &.column {
      padding: 31px 0 35px;
      .titleWrap {
        margin: 0 0 15px;
      }
      .column__ttl {
        font-size: 1.6rem;
      }
    }
  }
  .column__2col {
    display: block;
    .columnBox {
      width: 100%;
      margin: 23px 0 0;
      padding: 0 38px;
    }
    .articleCard {
      margin: 11px 0 0;
    }
    & + .column__2col {
      margin: 0;
    }
  }
  .articleCard__text {
    padding: 10px;
  }
  /* =================================
    article list
  ================================= */
  /*tab*/
  .ui-tabs .ui-tabs-panel {
    padding: 30px 0 0;
  }
  /*tabs Num*/
  #listNum.ui-tabs .ui-tabs-nav li {
    a {
      font-size: 1.7rem;
    }
  }
  .pageSummary {
    line-height: 1.6;
    margin: 25px 0 35px;
  }
  .articleList {
    margin: 26px 0 38px;
    li {
      display: block;
      .thumb {
        width: 100%;
      }
      & + li {
        margin: 15px 0 0;
      }
    }
    &__item {
      width: 100%;
      padding: 10px 15px;
      background: #fff;
      .articleList__ttl a {
        font-size: 1.7rem;
      }
      .articleList__text {
        font-size: 1.4rem;
        margin: 8px 0 0;
      }
    }
  }
  .pagenation {
    display: block;
    text-align: center;
    .pager {
      justify-content: center;
      li {
        font-size: 1.7rem;
        a {
          padding: 10px 14px;
        }
        span {

          padding: 10px 14px;
        }
      }
    }
    .listCount {
      font-size: 1.4rem;
      margin: 5px 0 0;
    }
  }
  /* =================================
    singlePage
  ================================= */
  .singlePage {
    width: 100%;
    border: none;
    margin: 0 0 40px;
    &::before {
      width: 100%;
      height: 3px;
      margin-left: 0;
    }
    .data {
      margin: 15px 0;
      .time {
        margin-left: 12px;
      }
      .view {
        margin-left: 8px;
      }
    }
    .articleMv {
      width: 100%;
      margin: 15px auto 2px;
    }
    .tocBox {
      margin: 30px 0 35px;
      &__ttl {
        font-size: 1.2rem;
        padding: 5px 8px 5px 10px;
        &::before {
          width: 10px;
          right: -4px;
        }
      }
      .tocList {
        padding: 15px 15px 20px;
        &__item {
          padding: 0 0 0 30px;
          a {
            font-size: 1.4rem;
          }
          & + .tocList__item {
            margin: 10px 0 0;
          }
          &::before {
            font-size: 1.5rem;
            font-weight: bold;
          }
        }
      }
    }
    &__in {
      padding: 0 15px 0;
      & > {
        h1 {
          font-size: 2.4rem;
          margin: 30px 0 15px;
        }
        h2 {
          font-size: 2.3rem;
          line-height: 1.35;
          padding: 11px 15px;
          margin: 30px 0 25px;
        }
        h3 {
          font-size: 2rem;
          line-height: 1.2;
          border-left: 4px solid $accent-color-dark;
          padding: 5px 10px 10px;
          margin: 40px 0 25px;
        }
        h4 {
          font-size: 1.8rem;
          line-height: 1.4;
          margin: 20px 0 25px;
          padding-bottom: 8px;
          border-bottom: 2px solid #969696;
          &::before {
            width: 100px;
            height: 2px;
          }
        }
        img {
          display: block;
          margin: 20px auto;
        }
        .imgBox--r,.imgBox--l {
          img {
            float: none;
            max-width: 100%;
            margin: 0;
          }
          p {
            margin-top: 18px;
          }
        }
        blockquote {
          padding: 20px 15px 5px 15px;
          border-left: 2px solid #0a4098;
        }
        blockquote:before{
          top: 5px;
          font-size: 20px;
        }
        blockquote p {
          padding: 0;
          margin: 8px 0;
          font-size: 1.4rem;
        }
        blockquote cite {
          font-size: 1.2rem;
        }
        ul {
          margin: 18px auto 35px;
          li {
            font-size: 1.5rem;
            line-height: 1.5;
            padding-left: 30px;
            &::before {
              width: 10px;
              height: 10px;
              top: 6px;
              left: 10px;
            }
            &+ li {
              margin: 18px 0 0;
            }
          }
        }
        ol {
          margin: 18px auto 35px;
          li {
            font-size: 1.5rem;
            line-height: 1.5;
            padding-left: 30px;
            &::before {
              font-size: 1.6rem;
            }
            &+ li {
              margin: 18px 0 0;
            }
          }
        }
        table {
          overflow-x: auto;
          white-space: nowrap;
          display: block;
          margin: 40px 0 25px;
          border: 1px solid #dde3e4;
          tr + tr {
            border-top: 1px solid #fff;
          }
          th {
            font-size: 1.4rem;
            min-width: initial;
            border: none;
            padding: 10px 8px;
          }
          td {
            width: 100%;
            border: none;
            padding: 10px 8px;
          }
        }
      }
    }
    .related--sm {
      display: block;
      margin: 35px 0;
      .related__ttl {
        font-size: 1.2rem;
        padding: 5px 5px 5px 8px;
        margin: 0 0 8px;
        &::before {
          width: 10px;
          right: -4px;
        }
      }
      a {
        display: block;
        margin-left: 0;
      }
    }
    .related--md {
      padding: 33px 8px 5px;
      margin: 25px 0 20px;
      .related__ttl {
        font-size: 1.2rem;
        padding: 5px 5px 5px 9px;
        &::before {
          content: "";
          width: 8px;
          left: -4px;
        }
      }
      .related__list__item {
        .thumb {
          width: 120px;
        }
        .related__txt {
          width: calc(100% - 120px);
          margin-left: 0;
          padding: 0 10px;
          a {
            font-size: 1.4rem;
            line-height: 1.5;
          }
        }
        .data {
          margin: 0 0 8px;
        }
        .time {
          margin-left: 0;
        }
        & + .related__list__item {
          margin: 10px 0 0;
        }
      }
    }
    .related--lg {
      padding: 30px 15px 36px;
      margin: 38px auto 0;
      .related__ttl {
        font-size: 2.6rem;
        margin: 0 0 32px;
      }
      .related__list__item {
        display: block;
        .thumb {
          width: 100%;
        }
        .related__txt {
          width: 100%;
          padding: 12px;
          a {
            font-size: 1.6rem;
            line-height: 1.5;
          }
        }
        .data {
          margin: 0 0 10px;
        }
        & + .related__list__item {
          margin: 20px 0 0;
        }
      }
    }
    .pointBox {
      padding: 35px 15px 15px;
      margin: 30px 0 20px;
      &__ttl {
        font-size: 2rem;
        line-height: 1.2;
        &__sub {
          font-size: 1.2rem;
          padding: 5px 5px 5px 9px;
          &::after {
            width: 8px;
            left: -4px;
          }
        }
      }
      p {
        margin: 15px 0 0;
      }
    }
    .pager {
      margin: 40px 0 30px;
      border-bottom: 1px dotted #363333;
      .prev,.next {
        width: 100%;
        flex-direction: column;

        span {
          font-size: 1.2rem;
          width: 100%;
          padding: 18px 0;
          display: block;
        }
        a {
          width: 100%;
          font-size: 1.4rem;
          line-height: 1.5;
          padding: 10px;
          display: block;
        }
      }
      .prev {
        span::before {
          border-width: 4px 8px 4px 0;
          margin-right: 5px;
        }
      }
      .next {
        span::after {
          border-width: 4px 0 4px 8px;
          margin-left: 5px;
        }
      }
    }
    .fbShareBox {
      display: block;
      margin: 30px 0 40px;
      .thumb {
        display: none;
      }
      .text {
        width: 100%;
        padding: 18px 0 20px;
        p {
          font-size: 1.8rem;
          line-height: 1.3;
          margin: 18px 0 0;
        }
      }
      .fbBtnWrap {
        padding: 2px 3px;
        margin: 20px 0 0;
      }
    }
  }
  .outBox {
    width: calc(100% + 30px);
    left: -15px;
  }
  /*おすすめ*/
  .recommend {
    padding: 35px 15px 30px;
    &__ttl {
      font-size: 2.3rem;
      padding: 11px 15px;
      margin: 0 0 30px;
      &__sub {
        font-size: 2rem;
        margin: 0 0 23px;
      }
    }
    .topParts {
      display: block;
      .recommend {
        &__thumb{
          width: 100%;
          margin: 0 auto;
          display: block;
          text-align: center;
        }
        &__table {
          width: 100%;
          margin: 20px 0 0;
          caption {
            font-size: 1.5rem;
            padding: 5px 0;
          }
          th {
            font-size: 1.2rem;
            padding: 5px 1px;
            & + th {
              border-left: 1px solid #dedede;
            }
          }
          td {
            font-size: 1.3rem;
            padding: 4px 1px;
            & + td {
              border-left: 1px solid #dedede;
            }
          }
        }
      }
    }
    .summary{
      font-size: 1.4rem;
    }
    .campaign {
      padding: 33px 15px 15px;
      &__ttl {
        font-size: 1.2rem;
        padding: 5px 5px 5px 9px;
        &::after {
          width: 8px;
          left: -4px;
        }
        &__sub {
          font-size: 2rem;
        }
      }
      p {
        font-size: 1.5rem;
        line-height: 1.8;
        margin: 10px 0 0;
      }
    }
  }
  .underSecWrap {
    padding: 25px 0 35px;
    .titleWrap {
      margin: 0 0 40px;
    }
    .column__ttl {
      font-size: 2.5rem;
      &::after {
        height: 3.5px;
      }
    }
    .articleList--2col .articleCard {
      padding-bottom: 10px;
      .articleCard__text {
        padding: 0 5px 0px 10px;
      }
    }
    .column__2col .columnBox {
      padding: 0 15px;
    }
  }
  .snsBarWrap {
    width: 100%;
    padding: 50px 15px 20px;
  }
  .snsBar {
    margin-top: 0;
    display: flex;
    li {
      width: 20%;
      a {
        font-size: 1.5rem;
        padding: 5px 0;
      }
      & + li {
        border-top: none;
      }
    }
  }

  /* =================================
    FX会社一覧
  ================================= */
  .clientListWrap {
    margin: 35px 0 40px;
  }
  .clientList {
    margin: 26px 0 38px;
  }
  .listCard {
    border-top: 3px solid $accent-color-dark;
    padding: 40px 15px 15px;
    &__ttl {
      font-size: 2.4rem;
      margin: 0 0 20px;
    }
    &__top {
      display: block;
      .thumb {
        width: 100%;
        margin: 0 0 20px;
        display: block;
      }
      .table--feature {
        width: 100%;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          font-size: 1.2rem;
          width: 33.33333%;
          height: 70px;
          padding: 38px 0 0;
          margin: 0;
          border-top:  1px solid #fff;
          border-left: 1px solid #fff;
          .icon {
            font-size: 1.8rem;
            top: 13px;
            &.sm {
              font-size: 2rem;
            }
            &.md {
              font-size: 2.2rem
            }
            &.lg {
              font-size: 2.6rem;
            }
          }
          &:nth-child(3n+1) {
            border-left: none;
          }
        }
      }
    }
    .tableWrap {
      display: block;
      margin: 25px 0 20px;
      table {
        border-bottom: 1px solid #dedede;
      }
      caption {
        font-size: 1.6rem;
        padding: 5px 0;
      }
      th {
        font-size: 1.2rem;
        padding: 5px 1px;
        border-left: 1px solid #dedede;
      }
      td {
        font-size: 1.3rem;
        padding: 4px 1px;
        border-left: 1px solid #dedede;
      }
      .table--basicinfo {
        width: 100%;
        border: 1px solid #dedede;
      }
      .table--spread {
        width: 100%;
        border: 1px solid #dedede;
        caption {
          border-left: none;
        }
      }
    }
    .desc {
      margin: 18px 0;
    }
    .campaign {
      padding: 20px 20px;
      &__ttl {
        font-size: 2rem;
        line-height: 1.2;
        margin: 0 0 15px;
      }
    }
    & + .listCard {
      margin: 20px 0 0;
    }
    .tag {
      font-size: 1.3rem;
      padding: 4px 20px;
      top: -3px;
      &::before {
        width: 10px;
        left: -6px;
      }
    }
  }

  /* =================================
    FX会社詳細
  ================================= */
  .clientDetail {
    background: #fff;
    &__ttl {
      font-size: 3rem;
      margin: 42px 0 30px;
      text-shadow: 0px 0px 5px rgba(231, 235, 243, 0.9),0px 0px 5px rgba(231, 235, 243, 0.9);
    }
    &Nav {
      margin: 0 0 20px;
      li {
        a {
          font-size: 1.3rem;
          padding: 7px 10px 7px 0;
          &::after {
            border-width: 8px 4px 0 4px;
            right: 10px;
          }
        }
      }
    }
    .desc__top {
      display: block;
      margin: 0 0 20px;
      .thumb {
        width: 100%;
        margin: 0 0 10px;
        img {
          display: block;
          margin: 0 auto;
        }
      }
      .descTableWrap {
        width: 100%;
        .table--feature {
          width: 100%;
          margin: 0 0 25px;
          display: flex;
          flex-wrap: wrap;
          li {
            font-size: 1.2rem;
            width: 33.333333%;
            height: 70px;
            padding: 38px 0 0;
            margin: 0;
            border-top: 1px solid #fff;
            border-left: 1px solid #fff;
            &:nth-child(3n+1) {
              border-left: none;
            }
            .icon {
              font-size: 1.8rem;
              top: 13px;
              &.sm {
                font-size: 2rem;
              }
              &.md {
                font-size: 2.2rem
              }
              &.lg {
                font-size: 2.6rem;
              }
            }
          }
        }
        .descTable__ttl {
          font-size: 1.7rem;
          line-height: 25px;
          border-left: 3px solid $accent-color-dark;
          padding-left: 5px;
          margin: 20px 0 10px;
        }
        .table--basicInfo {
          padding: 15px 10px;
          justify-content: space-between;
          dl {
            width: calc(33.333333% - 6px);
            display: block;
            text-align: center;
            & + dl {
              padding-left: 3px;
              &::before {
                font-size: 3rem;
                left: -8px;
              }
            }
            dt {
              font-size: 1.3rem;
            }
            dd {
              font-size: 1.6rem;
              margin-left: 0;
            }
          }
        }
        .table--spread {
          flex-wrap: wrap;
          justify-content: center;
          dl {
            width: calc(33.333333% - 3.333333px);
            max-width: 93px;
            height: 93px;
            dt {
              font-size: 1.3rem;
              &::before {
                width: 40px;
                height: 15px;
                margin: 15px auto 5px;
              }
            }
            dd {
              font-size: 2rem;
              line-height: 1.1;
              span {
                font-size: 1.3rem;
              }
            }
            & + dl {
              margin-left: 5px;
            }
          }
        }
      }
    }
    .conclusion {
      &__table {
        border: 2px solid #ed3b3b;
        padding: 15px 15px 5px;
      }
      dl {
        display: block;
        & + dl {
          margin: 15px 0 0;
        }
      }
      dt {
        font-size: 1.4rem;
        display: inline-block;
        width: auto;
        width: initial;
        min-width: 100px;
        padding: 5px 5px 5px 25px;
        background: #ed3b3b;
        position: relative;
        &::before {
          font-size: 1.4rem;
          left: 5px;
        }
      }
      dd {
        font-size: 1.5rem;
        width: 100%;
        padding: 5px 5px 9px;
      }
    }
    .clientRecommend {
      .recommend__list {
        li {
          font-size: 1.8rem;
          padding: 10px 15px 10px 40px;
          position: relative;
          &::before {
            margin-right: 0;
            position: absolute;
            top: 12px;
            left: 8px;
          }
        }
      }
    }
    .campaign {
      padding: 45px 15px 20px;
      margin: 50px 0 5px;
      display: block;
      &__ttl {
        font-size: 1.2rem;
        padding: 5px 5px 5px 8px;
        &::before {
          width: 10px;
          right: -4px;
        }
      }
      .thumb {
        max-width: auto;
        max-width: initial;
        display: block;
        margin: 0 auto;
      }
      .campaign__text {
        margin: 25px 0 0;
        .campaign__ttl__sub {
          font-size: 2.1rem;
        }
        p {
          margin: 12px 0 25px;
        }
      }
    }
  }
  .spec {
    padding: 30px 0 36px;
    caption {
      font-size: 2rem;
      margin: 0 0 15px;
      text-align: left;
    }
    .table--spec {
      table {
        overflow-x: auto;
        white-space: nowrap;
        display: block;
        margin: 40px 0 12px;
      }
      th {
        font-size: 1.3rem;
        padding: 8px 5px;
      }
      td {
        font-size: 1.4rem;
        padding: 8px 5px;
      }
      .notes {
        font-size: 1.3rem;
        line-height: 1.2;
        text-align: left;
        padding-left: 1em;
        text-indent: -1em;
      }
    }
    .btnWrap {
      margin: 58px 0 0;
    }
  }
  .contactForm {
    table {
      margin: 0;
    }
    input,textarea {
      border-radius: 0;
      -webkit-appearance: none;
    }
    tr {
      border-top: none;
      &:last-child {
        border-bottom: none;
      }
    }
    th {
      font-size: 1.2rem;
      font-weight: bold;
      background: transparent;
      padding: 10px 0;
      display: flex;
      align-items: center;
      width: 100%;
    }
    td {
      padding: 0;
      display: block;
      width: 100%;
      textarea {
        min-height: 90px;
      }
    }
    .required {
      font-size: .9rem;
      padding: 3px 4px;
      margin-left: 6px;
    }
    .confirmingTxt {
      font-size: 1.2rem;
      line-height: 1.65833;
      text-align: left;
      margin: 20px 0 18px;
    }
  }
  .completedForm {
    .completedMessage {
      font-size: 1.4rem;
      margin: 14px 0 0;
    }
    p {
      font-size: 1.2rem;
      text-align: left;
      margin: 20px 0 0;
    }
    table {
      width: 100%;
      margin: 40px auto 25px;
    }
    th,td {
      width: auto;
    }
    th {
      font-size: 1.5rem;
      padding: 12px 10px;
      background: url(/images/bg_border_green02.jpg) repeat left top;
    }
    td {
      padding: 12px 10px;
      border-bottom: 1px dashed #dcd8d0;
      .tel {
        address {
          font-size: 2.7rem;
          &::before {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            animation-duration: 2s;
            animation-timing-function: liner;
            animation-iteration-count: infinite;
            animation-name: vibrate02;
          }
        }
      }
      .receptionTime {
        margin: 5px 0 0;
        dt {
          font-size: 1.2rem;
          font-weight: bold;
          color: $accent-color;
        }
        dd {
          font-size: 1.2rem;
          margin-left: 0px;
        }
        dt,dd {
          display: block;
        }
      }
    }
  }
}